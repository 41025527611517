<template>
  <div class="pt-1">
    <alert type="info" text="warnings.no-store-card" class="mb-4" />
  </div>
  <div class="table-responsive adaptive-table" v-if="list.length > 0">
    <table class="table table-striped table-hover adaptive-table" style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in dataList" :key="row.id" @click="prepareInfo(row)">
          <td :data-label="$t('forms.billing.rows.amount')">
            {{row.gross_price}} {{row.currency}} - {{getLocalizedOrValue($i18n, `paymentMethod.${row.payment_method}`, row.payment_method)}}
            <a class="feather icon-file-text receipt-link" target="_blank"
               data-toggle="tooltip" data-placement="bottom"
               @click.stop
               :title="$t('forms.billing.receipt')" :href="row.receipt"></a>
          </td>
          <td :data-label="$t('forms.billing.rows.dateCreate')">
            <template v-if="row.create_date">
              {{ humanDate(row.create_date, $i18n.locale) }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useHumanDate } from '@/application/composables/humanDate.js'
import { defineAsyncComponent, onMounted, ref } from 'vue'
import { useTableHandlers } from '@/application/composables/messagesTable'
import { useI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler'
import { getLocalizedOrValue } from '@/application/utils/localization'

export default {
  name: 'ScheduledCampaignsTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
    license: {
      type: Object,
      required: true,
    },
  },
  components: {
    alert: defineAsyncComponent(() => import('@/application/components/elements/Alert.vue')),
  },
  setup(props) {
    const { humanDateTime, humanDate } = useHumanDate()
    const i18n = useI18n()
    const dataList = ref(props.list)
    const fields = ['forms.billing.rows.amount', 'forms.billing.rows.date']

    onMounted(() => {
      window.$(function() {
        window.$('[data-toggle="tooltip"]').tooltip()
      })
    })

    const { openModal } = useTableHandlers()
    const prepareInfo = (info) => {
      const obj = {
        'forms.billing.rows.date': info.create_date ? humanDateTime(info.create_date, i18n.locale) : '',
        'forms.billing.rows.currency': info.currency,
        'forms.billing.rows.price': info.price,
        'forms.billing.rows.tax': info.payment_tax,
        'forms.billing.rows.totalPrice': info.gross_price,
        'forms.billing.rows.paymentMethod': getLocalizedOrValue(i18n, `paymentMethod.${info.payment_method}`, info.payment_method),
      }

      if (info.coupon) {
        obj['forms.billing.rows.coupon'] = info.coupon
      }
      openModal(obj, 'details')
    }

    return {
      humanDate,
      fields,
      dataList,
      prepareInfo,
      getLocalizedOrValue,
    }
  },
}
</script>
